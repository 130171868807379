<template>
    <div class="advertise">
        <el-card>
            <el-row :gutter="20" class="operate">
                <el-col :span="3">
                    <el-input v-model="selectTitle" placeholder="请输入广告标题" clearable></el-input>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectType" placeholder="广告类型" filterable clearable>
                        <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectPosition" placeholder="广告位置" filterable clearable>
                        <el-option v-for="(item, index) in positionList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectAdvertisePageType" placeholder="广告页面" filterable clearable>
                        <el-option v-for="(item, index) in pageTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectJumpPageType" placeholder="跳转页面" filterable clearable>
                        <el-option v-for="(item, index) in pageTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectResourceType" placeholder="资源类型" filterable clearable>
                        <el-option v-for="(item, index) in resourceTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-col>
                <el-col :span="2">
                    <el-select v-model="selectStatus" placeholder="请选择状态" filterable clearable>
                        <el-option label="启用" :value="1"></el-option>
                        <el-option label="禁用" :value="0"></el-option>
                    </el-select>
                </el-col>
                <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
                <el-button @click="openDialog(form, true)" type="primary">添加广告</el-button>
            </el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
                <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
                <el-table-column prop="resource_type_name" label="资源类型" width="150"></el-table-column>
                <el-table-column label="资源Url" width="150" class-name="avatar">
                    <template #default="scope">
                        <el-avatar v-if="scope.row.resource_type === 1" :size="40" :src="scope.row.resource_url" shape="square"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" width="200"></el-table-column>
                <el-table-column prop="sub_title" label="子标题" width="200"></el-table-column>
                <el-table-column prop="advertise_type_name" label="广告类型" width="150"></el-table-column>
                <el-table-column prop="advertise_position_name" label="广告位置" width="150"></el-table-column>
                <el-table-column prop="advertise_page_type_name" label="广告页面类型" width="150"></el-table-column>
                <el-table-column prop="advertise_page" label="广告页面" width="150"></el-table-column>
                <el-table-column prop="jump_page_type_name" label="跳转页面类型" width="150"></el-table-column>
                <el-table-column prop="jump_page" label="跳转页面" width="150"></el-table-column>
                <el-table-column prop="width" label="宽度" width="150"></el-table-column>
                <el-table-column prop="height" label="高度" width="150"></el-table-column>
                <el-table-column label="状态" width="150">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="排序" width="160">
                    <template slot-scope="scope">
                        <el-input-number v-model="scope.row.sort" @change="changeSort(scope.row)" :controls="false" :min="0" size="small"></el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="180">
                    <template slot-scope="scope">{{scope.row.create_time | formatDate('yyyy-MM-dd hh:mm:ss')}}</template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template #default="scope">
                        <el-button @click="queryItem(scope.row.id)" type="primary" size="mini">查看</el-button>
                        <el-button @click="openDialog(scope.row, false)" type="warning" size="mini">修改</el-button>
                        <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>

            <!-- 添加弹窗 -->
            <el-dialog @close="closeDialog('addAdvertiseItem')" title="添加广告" :visible.sync="showDialog" class="add_dialog">
                <el-form :model="form" ref="addAdvertiseItem" :rules="rules">
                    <el-form-item label="广告标题" prop="title" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.title" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="标题图片" :label-width="formLabelWidth">
                        <el-upload :action="baseURL" :show-file-list="false" :on-success="handleTitleUrlSuccess" :before-upload="beforeUpload" class="avatar-uploader">
                            <img v-if="form.title_url" :src="form.title_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="副标题" prop="sub_title" :label-width="formLabelWidth">
                        <el-input v-model="form.sub_title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="广告类型" prop="advertise_type" :label-width="formLabelWidth">
                        <el-select v-model="form.advertise_type" placeholder="请选择广告类型" filterable clearable>
                            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="广告位置" prop="advertise_position" :label-width="formLabelWidth">
                        <el-select v-model="form.advertise_position" placeholder="请选择广告位置" filterable clearable>
                            <el-option v-for="(item, index) in positionList" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="广告页面类型" prop="advertise_page_type" :label-width="formLabelWidth">
                        <el-select v-model="form.advertise_page_type" placeholder="请选择广告页面类型" filterable clearable>
                            <el-option v-for="(item, index) in pageTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="广告页面" prop="advertise_page" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.advertise_page" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="跳转页面类型" prop="jump_page_type" :label-width="formLabelWidth">
                        <el-select v-model="form.jump_page_type" placeholder="请选择广告页面类型" filterable clearable>
                            <el-option v-for="(item, index) in pageTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="跳转页面" prop="jump_page" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.jump_page" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="资源类型" prop="resource_type" :label-width="formLabelWidth">
                        <el-select v-model="form.resource_type" placeholder="请选择资源类型" filterable clearable>
                            <el-option v-for="(item, index) in resourceTypeList" :key="index" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="资源链接" :label-width="formLabelWidth">
                        <template v-if="form.resource_type == 1">
                            <el-upload :action="baseURL" :show-file-list="false" :on-success="handleResourceUrlSuccess" :before-upload="beforeUpload" class="avatar-uploader">
                                <img v-if="form.resource_url" :src="form.resource_url" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </template>
                        <template v-else>
                            <el-input v-model="form.resource_url" autocomplete="off"></el-input>
                        </template>
                    </el-form-item>
                    <el-form-item label="宽度" prop="width" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.width" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="高度" prop="height" :label-width="formLabelWidth">
                        <el-col :span="12">
                            <el-input v-model="form.height" autocomplete="off"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="描述" prop="desc" :label-width="formLabelWidth">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item label="状态" prop="status" :label-width="formLabelWidth">
                        <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{form.status | statusText}}</span>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort" :label-width="formLabelWidth">
                        <el-col :span="8">
                            <el-input-number v-model="form.sort" autocomplete="off" :min="0" :controls="false"></el-input-number>
                        </el-col>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button v-if="isAddFlag" @click="addItem('addAdvertiseItem')" type="primary">确 定</el-button>
                    <el-button v-else @click="editItem('addAdvertiseItem')" type="primary">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 查看弹窗 -->
            <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
                <div class="check_div">
                    <div class="check_label">标题：</div>
                    <div class="check_data">{{checkData.title}}</div>
                </div>
                <div class="check_div" v-if="checkData.title_url">
                    <div class="check_label">标题链接：</div>
                    <div class="check_data"><el-avatar :src="checkData.title_url"></el-avatar></div>
                </div>
                <div class="check_div">
                    <div class="check_label">副标题：</div>
                    <div class="check_data">{{checkData.sub_title}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">广告类型：</div>
                    <div class="check_data">{{checkData.advertise_type_name}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">广告位置：</div>
                    <div class="check_data">{{checkData.advertise_position_name}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">广告页面类型：</div>
                    <div class="check_data">{{checkData.advertise_page_type_name}}</div>
                </div>
                <div class="check_div" v-if="checkData.advertise_page">
                    <div class="check_label">广告页面：</div>
                    <div class="check_data">{{checkData.advertise_page}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">跳转页面类型：</div>
                    <div class="check_data">{{checkData.jump_page_type_name}}</div>
                </div>
                <div class="check_div" v-if="checkData.jump_page">
                    <div class="check_label">跳转页面：</div>
                    <div class="check_data">{{checkData.jump_page}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">资源类型：</div>
                    <div class="check_data">{{checkData.resource_type_name}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">资源链接：</div>
                    <div class="check_data" v-if="checkData.resource_type === 1"><el-avatar :src="checkData.resource_url"></el-avatar></div>
                    <div class="check_data" v-else>{{checkData.resource_url}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">宽度：</div>
                    <div class="check_data">{{checkData.width}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">高度：</div>
                    <div class="check_data">{{checkData.height}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">描述：</div>
                    <div class="check_data">{{checkData.desc}}</div>
                </div>
                <div class="check_div">
                    <div class="check_label">状态：</div>
                    <div class="check_data">{{checkData.status | statusText}}</div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="checkItem = false">确 定</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
    import {getAdvertiseList, addAdvertiseItem, getAdvertiseAll, getAdvertiseProfile, queryAdvertiseItem, editAdvertiseItem, deleteAdvertiseItem} from '@api';
    export default {
        name: 'Advertise',
        components: {},
        data() {
            return {
                params: { // 请求分页参数
                    page: 1,
                    page_size: 10
                },
                typeList: [], // 广告类型
                positionList: [], // 广告位置
                pageTypeList: [], // 页面类型
                resourceTypeList: [], // 资源类型
                tableData: [], // 表格数据
                loading: true, // 表格加载等待
                showDialog: false, // 是否显示弹窗
                isAddFlag: false, // 是否点击添加用户
                form: { // 添加表单
                    title: '',
                    title_url: '',
                    sub_title: '',
                    advertise_type: '',
                    advertise_position: '',
                    advertise_page_type: '',
                    advertise_page: '',
                    resource_type: '',
                    resource_url: '',
                    resource_url_change: false,
                    jump_page_type: '',
                    jump_page: '',
                    width: '',
                    height: '',
                    desc: '',
                    sort: 0,
                    status: 0,
                },
                initForm: {
                    title: '',
                    title_url: '',
                    sub_title: '',
                    advertise_type: '',
                    advertise_position: '',
                    advertise_page_type: '',
                    advertise_page: '',
                    resource_type: '',
                    resource_url: '',
                    resource_url_change: false,
                    jump_page_type: '',
                    jump_page: '',
                    width: '',
                    height: '',
                    desc: '',
                    sort: 0,
                    status: 0,
                },
                rules: {
                    title: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ],
                    advertise_type: [
                        {required: true, message: '请选择广告类型', trigger: 'blur'}
                    ],
                    advertise_position: [
                        {required: true, message: '请选择广告位置', trigger: 'blur'}
                    ],
                    advertise_page_type: [
                        {required: true, message: '请选择广告页面类型', trigger: 'blur'}
                    ],
                    resource_type: [
                        {required: true, message: '请选择资源类型', trigger: 'blur'}
                    ],
                    resource_url: [
                        {required: true, message: '请输入资源链接', trigger: 'blur'}
                    ],
                    width: [
                        {required: true, message: '请输入广告宽度', trigger: 'blur'}
                    ],
                    height: [
                        {required: true, message: '请输入广告高度', trigger: 'blur'}
                    ],
                },
                baseURL: `${this.$store.state.config.baseURL}/upload`, // 上传图片地址
                formLabelWidth: '120px', // from表单label的宽度
                total: 0, // 数据总条数
                checkItem: false, // 查看每条的弹窗
                checkData: {}, // 查询每条时的数据
                selectTitle: '', // 搜索广告标题
                selectSubTitle: '', // 搜索广告副标题
                selectType: '', // 搜索广告类型
                selectPosition: '', // 搜索广告位置
                selectAdvertisePageType: '', // 搜索广告页面类型
                selectJumpPageType: '', // 搜索跳转页面类型
                selectResourceType: '', // 搜索资源类型
                selectStatus: '', // 搜索状态
            }
        },
        created() {
            this.initData();
            this.getData(this.params);
        },
        filters: {
            statusText(status) {
                if (status > 0) {
                    return '已审核';
                }
                return '未审核';
            }
        },
        methods:{
            initData() {
                getAdvertiseProfile().then(res => {
                    this.typeList = res.data.advertise_type;
                    this.positionList = res.data.advertise_position;
                    this.pageTypeList = res.data.page_type;
                    this.resourceTypeList = res.data.resource_type;
                })
            },
            search() { // 搜索
                const {selectTitle, selectSubTitle, selectType, selectPosition, selectAdvertisePageType, selectJumpPageType, selectResourceType, selectStatus} = this;
                this.params.title = selectTitle;
                this.params.sub_title = selectSubTitle;
                this.params.advertise_type = selectType;
                this.params.advertise_position = selectPosition;
                this.params.advertise_page_type = selectAdvertisePageType;
                this.params.jump_page_type = selectJumpPageType;
                this.params.resource_type = selectResourceType;
                this.params.status = selectStatus;
                this.getData(this.params);
            },
            addItem(refName) { // 添加
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    this.form.advertise_type = parseInt(this.form.advertise_type), // 广告类型
                    this.form.advertise_position = parseInt(this.form.advertise_position), // 广告位置
                    this.form.advertise_page_type = parseInt(this.form.advertise_page_type), // 广告页面类型
                    this.form.jump_page_type = parseInt(this.form.jump_page_type), // 跳转页面类型
                    this.form.resource_type = parseInt(this.form.resource_type), // 资源类型
                    this.form.width = parseInt(this.form.width), // 宽度
                    this.form.height = parseInt(this.form.height), // 高度
                    this.form.status = parseInt(this.form.status); // 状态
                    addAdvertiseItem(this.form).then(() => {
                        this.showDialog = false;
                        this.getData(this.params);
                        this.$message.success('添加成功!');
                        this.initFormData();
                    });
                });
            },
            queryItem(id) { // 查询单条数据
                this.checkItem = true;
                this.checkData = {};
                queryAdvertiseItem(id).then(res => this.checkData = res.data);
            },
            editItem(refName) { // 修改单条数据
                this.$refs[refName].validate(valid => {
                    if (!valid) return false;
                    const {form, form: {id}} = this;
                    const body = form;
                    body.advertise_type = parseInt(body.advertise_type), // 广告类型
                    body.advertise_position = parseInt(body.advertise_position), // 广告位置
                    body.advertise_page_type = parseInt(body.advertise_page_type), // 广告页面类型
                    body.jump_page_type = parseInt(body.jump_page_type), // 跳转页面类型
                    body.resource_type = parseInt(body.resource_type), // 资源类型
                    body.width = parseInt(body.width), // 宽度
                    body.height = parseInt(body.height), // 高度
                    body.status = parseInt(body.status); // 状态
                    body.fields = Object.keys(this.initForm);
                    editAdvertiseItem(id, body).then(() => {
                        this.showDialog = false;
                        this.$message.success('修改成功！');
                        this.getData(this.params);
                    });
                });
            },
            deleteItem(id) { // 删除单挑数据
                this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteAdvertiseItem(id).then(() => {
                        this.$message.success('删除成功!');
                        this.getData(this.params);
                    });
                }).catch(() => this.$message.info('已取消删除'));
            },
            changeStatus(item) {
                item.fields = ["status"];
                item.status = item.status ? 1 : 0;
                editAdvertiseItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                });
            },
            changeSort(item) {
                item.fields = ["sort"];
                editAdvertiseItem(item.id, item).then(() => {
                    this.$message.success('修改成功！');
                    this.getData(this.params);
                })
            },
            handleTitleUrlSuccess(res, file) { // 上传图片
                this.form.title_url = res.data.url;
            },
            handleResourceUrlSuccess(res, file) { // 上传图片
                this.form.resource_url = res.data.url;
                this.form.resource_url_change = true;
            },
            beforeUpload(file) { // 上传图片
                const isImg = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' || file.type === 'image/webp';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isImg) this.$message.error('上传头像图片只能是 JPG/gif/jpeg/png/webp 格式!');
                if (!isLt2M) this.$message.error('上传头像图片大小不能超过 2MB!');
                return isImg && isLt2M;
            },
            openDialog(item, isAdd) { // 打开弹窗
                this.form = {...item};
                if (!isAdd) {
                    this.form.resource_url_change = false;
                    this.form.advertise_type = this.form.advertise_type + ''; // 广告类型
                    this.form.advertise_position = this.form.advertise_position + ''; // 广告位置
                    this.form.advertise_page_type = this.form.advertise_page_type + ''; // 广告页面类型
                    this.form.jump_page_type = this.form.jump_page_type + ''; // 跳转页面类型
                    this.form.resource_type = this.form.resource_type + ''; // 资源类型
                }
                this.showDialog = true;
                this.isAddFlag = isAdd;
            },
            closeDialog(refName) { // 关闭弹窗
                this.showDialog = false;
                this.initFormData();
                this.$refs[refName].resetFields();
            },
            cancel() {
                this.showDialog = false;
            },
            handleCurrentChange(val) { // 获取页数
                this.params.page = val;
                this.getData(this.params);
            },
            handleSizeChange(val) { // 获取每页条数
                this.params.page_size = val;
                this.getData(this.params);
            },
            getData(params) {
                this.loading = true;
                getAdvertiseList(params).then(res => {
                    this.loading = false;
                    const {data, count} = res.data;
                    this.total = count;
                    this.tableData = data;
                }).catch(() => {
                    this.loading = false;
                })
            },
            initFormData() { // 初始化数据
                this.form = this.initForm;
            }
        },
    }
</script>

<style scoped>

</style>